import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;

    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;
    }
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0 0 2rem;
    font-size: 0.875rem;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Kambodža - Dobrodružství není nikdy dost!</Title>
            <Date>14. 11. 2022</Date>
            <p>Seděla jsem před letištěm v Surat Thani a čekala na svůj let do Siem Reap (Cambodia). Během chvilky se u mě zastavil Tom (kluk z Rakouska) a zeptal se, co se mi stalo.</p>
            <p><i>„Kousnul mě pes.“<br />„Pes? Co jsi mu udělala?<br />„Nic, jen jsem vedle něj spadla se skútrem, když měl zrovna půlnoc.“</i></p>
            <p>Dali jsme se do řeči a vyprávěli si cestovatelské zážitky. Dvě hodiny čekání utekly jako voda a já běžela, lépe řečeno pajdala, na letadlo.</p>
            <p>Po příletu jsem se setkala s kamarády, ubytovali jsme se a naplánovali návštěvy dalších míst. Během několikadenního pobytu v Siem Reapu jsem navštívila místní Royal Angkor International Hospital, což byla asi nejhezčí nemocnice, ve které jsem kdy byla. Nutno ale podotknout, že při dalších cestách do ciziny jsem nemocnice vynechala ze svých „must see” míst. Snad jsem to teď nezakřikla. </p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/cambodia1.jpg" alt="Angkor Wat - Siem Rea"></StaticImage>
                <StyledFigcaption>(Photo by Julia: Angkor Wat - Siem Rea)</StyledFigcaption>
            </StyledFigure>
            <p>Po několika dnech ve vnitrozemí jsme se vydali na jihozápad země. Naplánovanou destinací byly ostrovy Koh Rong a Koh Rong Sanloem. Po příletu na letiště v Sihanoukville jsme si vzali taxi a spolu s Indem, který se k nám přidal, vyrazili do přístavu. Cílem bylo stihnout poslední Ferry Taxi na ostrov Koh Rong. Cesta trvala asi hodinu a taxikář s Indem toho dost věděli o České republice, a tak se konverzace točila hlavně kolem toho. Hodina jízdy utekla jako nic, my dorazili do přístavu a 50 minut čekání jsme si šli zpříjemnit do restaurace.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/cambodia2.jpg" alt="ostrov Koh Rong Sanloem, Cambodia"></StaticImage>
                <StyledFigcaption>(Photo by Julia: ostrov Koh Rong Sanloem, Cambodia)</StyledFigcaption>
            </StyledFigure>
            <p>Objednali jsme si a povídali si. Mrkla jsem na hodinky a došlo mi, že je čas na antibiotika. Začala jsem hledat látkovou taštičku z nemocnice. Po chvilce hledání mě polilo horko a vykřikla jsem: „PAS!“ Nechápající kamarádi se na mě podívali. „Sakra, já nechala v taxíku pas! V taštičce s léky v přihrádce za řidičem.“</p>
            <p>Co teď? Nenapadlo mě nic lepšího, než vyběhnout zpět na hlavní a hledat bílý Lexus SUV. Dobrý nápad, jenže to by nesměly být všechny taxíky bílý Lexus SUV. Do háje. No nic, vydala jsem se k prvnímu a popsala, co se stalo. Vzal si na mě číslo a řekl, ať čekám. Vrátila jsem se do restaurace a za pár minut mi zazvonil telefon.</p>
            <p><i>„Musíš vyjít ven, vzít si tuk-tuk a jet do restaurace Blue Storm.“<br />„Jasný, běžím!“</i></p>
            <p>Nechala jsem kamarádům všechny své věci. Kdybych nestihla Ferry, nevadí. Dorazím ráno.</p>
            <p>Scéna jako z akčního filmu – vylítla jsem ven, čapla prvního tuk-tuka a zavelela: „Restaurant Blue Storm and quickly please.“ Když mi došlo, že řidič netuší, co quickly znamená, zjednodušila jsem to na: „Fast, Fast!“ Asi po 400 metrech na mě z restaurace mával řidič taxi mou taštičkou. Rozloučil se se mnou slovy: „Co zůstane u mě v taxi, se nikdy neztratí.“</p>
            <p>Ufff. Tak jsi zase pokoušela své štěstí, Julie! To už by mohlo stačit. Zatím.</p>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
